import * as React from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
} from 'react-admin';
import Aside from './Aside';
const TrackEdit = ({...props }) => (
    <Edit title='Edit Track' aside={<Aside />}  {...props}>
        <SimpleForm>
            <TextInput source="convention_id" validate={required()} />
            <TextInput source="name" validate={required()} />
        	<TextInput source="membership" validate={required()} />
        	<BooleanInput source="disabled" />
        	<RichTextInput source="upgrade_message" toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} validate={required()} />
        </SimpleForm>
    </Edit>
);

export default TrackEdit;