import * as React from 'react';
import {
    Datagrid,
    Filter,
    List,
    SearchInput,
    TextField,
} from 'react-admin';

import Aside from './Aside';
import MembershipEditEmbedded from './MembershipEditEmbedded';

const MembershipFilter = ({...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const MembershipList = ({...props }) => (
    <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        filters={<MembershipFilter/>}
        aside={<Aside />}
        bulkActionButtons={false}
    >
        <Datagrid
            rowClick='edit'
            expand={<MembershipEditEmbedded />}
            optimized
        >
        	<TextField label='Membership ID' source="membership_id" />
        	<TextField source="name" />
        </Datagrid>
    </List>
);

export default MembershipList;
