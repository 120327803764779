import * as React from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    Create,
    FormTab,
    TabbedForm,
    TextInput,
    required,
    BooleanInput,
} from 'react-admin';

import Aside from './Aside';

const TrackCreate = ({...props }) => (
    <Create {...props} aside={<Aside />} >
        <TabbedForm>
            <FormTab label="Track Information" path="">
                <TextInput source="convention_id" validate={required()} />
                <TextInput source="name" validate={required()} />
        		<TextInput source="membership" validate={required()} />
        		<BooleanInput source="disabled" />
        		<RichTextInput source="upgrade_message" toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} validate={required()} />
            </FormTab>
        </TabbedForm>
    </Create>
);

export default TrackCreate;