import fakeRestProvider from 'ra-data-fakerest';
import data from './data';
import { fetchUtils } from 'react-admin';
import CustomDataProvider from './CustomDataProvider';
// eslint-disable-next-line
const localDataProvider = fakeRestProvider(data, true);
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};
// eslint-disable-next-line
const apiDataProvider = CustomDataProvider('/v1', httpClient);

export default apiDataProvider;