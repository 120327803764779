import * as React from 'react';
import {
    Datagrid,
    Filter,
    List,
    SearchInput,
    TextField,
    NumberField
} from 'react-admin';

import Aside from './Aside';
import EmailEditEmbedded from './EmailEditEmbedded';

const EmailFilter = ({...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const EmailList = ({...props }) => (
    <List
        {...props}
        sort={{ field: 'email_id', order: 'ASC' }}
        filters={<EmailFilter/>}
        aside={<Aside />}
        bulkActionButtons={false}
    >
        <Datagrid
            rowClick='edit'
            expand={<EmailEditEmbedded />}
            optimized
        >
        	<NumberField label='Email ID' source="email_id" />
        	<NumberField label='Convention ID' source="convention_id" />
        	<TextField label='From Address' source="from_address" />
        	<TextField label='Subject' source="subject" />
        	<TextField label='Body HTML' source="body_html" />
        	<TextField label='Body Text' source="body_text" />
        </Datagrid>
    </List>
);

export default EmailList;
