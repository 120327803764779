import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    BooleanInput,
    NumberInput,
} from 'react-admin';
import Aside from './Aside';
const AttendeeEdit = ({...props }) => (
    <Edit title='Edit Attendee' aside={<Aside />}  {...props}>
        <SimpleForm>
                <NumberInput source="convention_id" />
                <TextInput source="first_name" />
                <TextInput source="last_name" /> 
                <TextInput source="display_name" />
                <NumberInput source="age" />
                <TextInput source="email" />
                <TextInput source="country" />  
                <TextInput source="post_code" />
                <BooleanInput label="Disable User Account" source="disabled"/>
                <NumberInput source="base_price" />
                <ReferenceArrayInput label="Roles:" source="membership_id" reference="membership">
    				<SelectArrayInput optionText="name" />
				</ReferenceArrayInput>
				<TextInput
        	        label="Membership UDF (JSON)"
        	        source= 'membership_udf'
                    multiline={true}
        	        format={ value_json => JSON.stringify(value_json) }
        	        parse= { value_json => JSON.parse(value_json) }
                />
                <TextInput
        	        label="Attendee UDF (JSON)"
        	        source= 'attendee_udf'
                    multiline={true}
        	        format={ value_json => JSON.stringify(value_json) }
        	        parse= { value_json => JSON.parse(value_json) }
                />
				<TextInput source="notes" />
        </SimpleForm>
    </Edit>
);

export default AttendeeEdit;