export default {
    attendee: [
        {
            attendee_id: 1,
            convention_id: 1,
            first_name: 'Peter',
            last_name: 'Smith',
            display_name: 'Demo Husky',
            age: 36,
            email: 'wolf@admin.com',
            country: 'USA',
            post_code: '99999',
            base_price: 2000,
            disabled: true,
            membership_id: [1],
        },
        {
            attendee_id: 2,
            convention_id: 1,
            first_name: 'Mary',
            last_name: 'Keffer',
            display_name: 'Demo Tiger',
            age: 19,
            email: 'tiger@admin.com',
            country: 'USA',
            post_code: '55555',
            base_price: 3000,
            disabled: false,
            membership_id: [2,3],
        },
        {
            attendee_id: 3,
            convention_id: 1,
            first_name: 'Bob',
            last_name: 'Yaffy',
            display_name: 'Demo Elephant',
            age: 23,
            email: 'elephant@admin.com',
            country: 'USA',
            post_code: '33333',
            base_price: 5000,
           	disabled: false,
            membership_id: [1,2,3],
        },
    ],
    membership: [
        {
            membership_id: 1,
            convention_id: 1,
            name: 'Basic',
        },
        {
            membership_id: 2,
            convention_id: 1,
            name: 'Sponsor',
        },
        {
            membership_id: 3,
            convention_id: 1,
            name: 'Super Sponsor',
        },
    ],
    convention: [
        {
            convention_id: 1,
            name: 'Convention 2010',
            description: 'This is the first one!',
        },
        {
            convention_id: 2,
            name: 'Convention 2020',
            description: 'This is the second one!',
        },
        {
            convention_id: 3,
            name: 'Convention 2021',
            description: 'This is the third and final one! We are done with this shit',
        },
    ],
    track: [
        {
            convention_id: 1,
            track_id: 1,
            name: 'Room 1',
            membership: [1,2],
            disabled: false,
            upgrade_message: 'To upgrade your membership to this level, please <a href=”https://origin.website.com/upgrade/”>click this link</a>',
        },
        {
            convention_id: 1,
            track_id: 2,
            name: 'Room 2',
            membership: [1,2,3],
            disabled: false,
            upgrade_message: 'To upgrade your membership to this level, please <a href=”https://origin.website.com/upgrade/”>click this link</a>',
        },
        {
            convention_id: 1,
            track_id: 3,
            name: 'Room 3',
            membership: [3],
            disabled: false,
            upgrade_message: 'To upgrade your membership to this level, please <a href=”https://origin.website.com/upgrade/”>click this link</a>',
         },
    ],
    event: [
        {
            convention_id: 1,
            name: 'Event 1',
            track_id: 1,
            event_id: 1,
            html: '<i>This</i> is the body that will show the details of the event when a user clicks on it in the schedule.',
            start_time: '2010-01-31T12:00:00Z',
            end_time: '2010-01-31T13:00:00Z',
            host: 'Sheldon',
            disabled: false,
        },
        {
            convention_id: 1,
            name: 'Event 2',
            track_id: 2,
            event_id: 2,
            html: '<i>This</i> is the body that will show the details of the event when a user clicks on it in the schedule.',
            start_time: '2010-01-31T13:00:00Z',
            end_time: '2010-01-31T14:00:00Z',
            host: 'Bob',
            disabled: false,
        },
        {
            convention_id: 1,
            name: 'Event 3',
            track_id: 2,
            event_id: 3,
            html: '<i>This</i> is the body that will show the details of the event when a user clicks on it in the schedule.',
            start_time: '2010-01-31T14:00:00Z',
            end_time: '2010-01-31T15:00:00Z',
            host: 'Mary',
            disabled: false,
        },
    ],
    config: [
        {
            module: 100,
            key: 'key',
            value: 1,
            value_json: '{}',
            disabled: false,
        },
    ],
};
