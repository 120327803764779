import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

import Aside from './Aside';

const MembershipCreate = ({...props }) => (
    <Create {...props} aside={<Aside />} >
        <SimpleForm redirect="list">
            <TextInput
                source="name"
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export default MembershipCreate;