import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
} from 'react-admin';
import Aside from './Aside';

const ConfigEdit = ({...props}) => (
    <Edit title='Edit Configuration' aside={<Aside />}  {...props}>
        <SimpleForm>
    		<TextInput source="key"  validate={required()}/>
    		<TextInput source="module"  validate={required()}/>
        	<TextInput
        	    source= 'value_json'
        	    format={ value_json => JSON.stringify(value_json) }
        	    parse= { value_json => JSON.parse(value_json) }
        	    validate={required()}
            />
        	<BooleanInput source="disabled" />
        </SimpleForm>
    </Edit>
);

export default ConfigEdit;