import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    main: {
        [theme.breakpoints.up('sm')]: {
            width: 200,
            margin: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            overflowX: 'hidden',
            margin: 0,
        },
    },
}));

const Aside = () => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Typography variant="h6">Memberships</Typography>
            <Typography variant="body2">
                This section is used to modify or adjust memberships.  These memberships link back to specific events that an attendee may have access to
            </Typography>
        </div>
    );
};

export default Aside;
