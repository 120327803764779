import * as React from 'react';
import {
    Datagrid,
    Filter,
    List,
    SearchInput,
    TextField,
    BooleanField,
} from 'react-admin';
import Aside from './Aside';
import EventEditEmbedded from './EventEditEmbedded'
const EventFilter = ({...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const EventList = ({...props }) => (
    <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        filters={<EventFilter/>}
        aside={<Aside />}
        bulkActionButtons={false}
    >
        <Datagrid
            rowClick='edit'
            expand={<EventEditEmbedded />}
            optimized
        >
        	<TextField label='Track ID' source="track_id" />
        	<TextField source="name" />
        	<TextField label='Convention ID' source="convention_id" />
        	<TextField source="html" />
        	<TextField source="start_time" />
        	<TextField source="end_time" />
        	<TextField source="host" />
        	<BooleanField source="disabled" />
        </Datagrid>
    </List>
);

export default EventList;
