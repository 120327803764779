import * as React from 'react';
import RichTextInput from 'ra-input-rich-text';
import {    Edit,
            SimpleForm,
            TextInput,
            required
} from 'react-admin';

const EmailEditEmbedded = ({...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm>
            <TextInput
                source="convention_id"
                label='Convention ID'
                defaultValue=""
                validate={required()}
            />
            <TextInput
                source="from_address"
                label='From Address'
                defaultValue=""
                validate={required()}
            />
            <TextInput
                source="subject"
                label='Subject'
                defaultValue=""
                validate={required()}
            />
            <RichTextInput
                source="body_html"
                label='Body HTML'
                toolbar={[ ['bold', 'italic', 'underline', 'link'] ]}
                validate={required()} />
            <TextInput
                source="body_text"
                label='Body Text'
                defaultValue=""
                validate={required()}
            />
        </SimpleForm>
    </Edit>
);

export default EmailEditEmbedded;
