import SettingsIcon from '@material-ui/icons/Settings';
import ConfigEdit from './ConfigEdit';
import ConfigList from './ConfigList';
import ConfigCreate from './ConfigCreate';

export default {
    list: ConfigList,
    create: ConfigCreate,
    edit: ConfigEdit,
    icon: SettingsIcon,
};