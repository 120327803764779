import * as React from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
} from 'react-admin';

import Aside from './Aside';

const EventCreate = ({...props }) => (
    <Create {...props} aside={<Aside />} >
        <SimpleForm>
            <TextInput source="name" validate={required()} />
        	<TextInput label='Convention ID' source="convention_id" validate={required()} />
        	<RichTextInput source="html" toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} validate={required()} />
        	<TextInput source="start_time" validate={required()} />
        	<TextInput source="end_time" validate={required()} />
        	<TextInput source="host" validate={required()} />
        	<BooleanInput source="disabled" />
        </SimpleForm>
    </Create>
);

export default EventCreate;