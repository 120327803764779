import * as React from 'react';
import {
    Datagrid,
    Filter,
    List,
    SearchInput,
    TextField,
    TextInput,
    NumberField,
} from 'react-admin';

import Aside from './Aside';
import ConfigEditEmbedded from './ConfigEditEmbedded';
const ConfigFilter = ({...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="username" />
        <TextInput source="email" />
    </Filter>
);

const ConfigList = ({...props }) => (
    <List
        {...props}
        filters={<ConfigFilter/>}
        sort={{ field: 'key', order: 'ASC' }}
        aside={<Aside />}
        bulkActionButtons={false}
        exporter={false}
    >
    <Datagrid
        rowClick='edit'
        expand={<ConfigEditEmbedded />}
        optimized
    >
    	<NumberField source="config_id" />
    	<TextField source="key" />
    	<TextField source="module" />
    	<TextField source= 'value_json' />
    </Datagrid>
   		
    </List>
);

export default ConfigList;