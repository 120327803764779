import * as React from 'react';
import RichTextInput from 'ra-input-rich-text';
import { 	Edit, 
			SimpleForm, 
			TextInput,
			BooleanInput,
			required } from 'react-admin';

const EventEditEmbedded = ({...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
        	<TextInput label='Convention ID' source="convention_id" validate={required()} />
        	<RichTextInput source="html" toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} validate={required()} />
        	<TextInput source="start_time" validate={required()} />
        	<TextInput source="end_time" validate={required()} />
        	<TextInput source="host" validate={required()} />
        	<BooleanInput source="disabled" />
        </SimpleForm>
    </Edit>
);

export default EventEditEmbedded;
