import * as React from 'react';
import {
    Datagrid,
    Filter,
    List,
    SearchInput,
    TextField,
    TextInput,
    BooleanField,
    NumberField,
} from 'react-admin';

import Aside from './Aside';

const UserFilter = ({...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="username" />
        <TextInput source="email" />
    </Filter>
);

const AttendeeList = ({...props }) => (
    <List
        {...props}
        filters={<UserFilter/>}
        sort={{ field: 'convention_id', order: 'DESC' }}
        aside={<Aside />}
        bulkActionButtons={false}
        exporter={false}
    >
    <Datagrid
        rowClick='edit'
        optimized
    >
    	<NumberField label="Convention ID" source="convention_id" />
    	<NumberField label="Attendee ID" source="attendee_id" />
    	<TextField source="first_name" />
    	<TextField source="last_name" />
    	<TextField source="display_name" />
        <TextField source="email" />
        <TextField source="display_name" />
		<BooleanField source="disabled" />
    </Datagrid>
    </List>
);

export default AttendeeList;
