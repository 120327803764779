import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import TrackCreate from './TrackCreate';
import TrackEdit from './TrackEdit';
import TrackList from './TrackList';

export default {
    list: TrackList,
    create: TrackCreate,
    edit: TrackEdit,
    icon: MeetingRoomIcon,
};
