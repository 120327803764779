import * as React from 'react';
import { Edit, 
		 SimpleForm, 
		 TextInput, 
		 required,
		 BooleanInput 
} from 'react-admin';

const ConfigEditEmbedded = ({...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm>
    		<TextInput source="key"  validate={required()}/>
        	<TextInput
        	    source= 'value_json'
        	    format={ value_json => JSON.stringify(value_json) }
        	    validate={required()}/>
        	<TextInput source="module"  validate={required()}/>
    		<BooleanInput source="disabled" />
        </SimpleForm>
    </Edit>
);

export default ConfigEditEmbedded;