import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';
import Aside from './Aside';
const MembershipEdit = ({...props }) => (
    <Edit title='Edit Membership' aside={<Aside />}  {...props}>
        <SimpleForm>
            <TextInput
                source="name"
                defaultValue=""
                validate={required()}
            />
        </SimpleForm>
    </Edit>
);

MembershipEdit.propTypes = {
    membership_id: PropTypes.any.isRequired,
};

export default MembershipEdit;