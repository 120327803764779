import * as React from 'react';
import PropTypes from 'prop-types';
import RichTextInput from 'ra-input-rich-text';
import { 	Edit, 
			SimpleForm, 
			TextInput,
			BooleanInput,
			required } from 'react-admin';

const TrackEditEmbedded = ({...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm>
            <TextInput source="convention_id" validate={required()} />
            <TextInput source="name" validate={required()} />
        	<TextInput source="membership" validate={required()} />
        	<BooleanInput source="disabled" />
        	<RichTextInput source="upgrade_message" toolbar={[ ['bold', 'italic', 'underline', 'link'] ]} validate={required()} />
        </SimpleForm>
    </Edit>
);

TrackEditEmbedded.propTypes = {
    record: PropTypes.object,
    basePath: PropTypes.string,
    resource: PropTypes.string,
    id: PropTypes.string,
};

export default TrackEditEmbedded;
