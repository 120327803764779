import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    DateTimeInput,
    required,
    SimpleFormIterator,
    ArrayInput,
} from 'react-admin';
import Aside from './Aside';
const ConventionEdit = ({...props }) => (
    <Edit title='Edit Convention' aside={<Aside />}  {...props}>
        <SimpleForm redirect="list">
            <TextInput
                source="name"
                validate={required()}
            />
            <TextInput
                source="description"
                validate={required()}
            />
            <DateTimeInput
                label="Earliest Email Date"
                source="earliest_email_date"
                options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }}
            />
            <ArrayInput label="Force Email Dates" source="force_email_dates">
                <SimpleFormIterator>
                    <DateTimeInput
                        label="Force Email Date"
                        options={{ format: 'DD/MM/YYYY, HH:mm:ss', ampm: false, clearable: true }} />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="enabled" defaultValue={true} />
            <TextInput
        	    label="Convention UDF (JSON)"
        	    source= 'convention_udf'
                multiline={true}
        	    format={ value_json => JSON.stringify(value_json) }
        	    parse= { value_json => JSON.parse(value_json) }
            />
        </SimpleForm>
    </Edit>
);

export default ConventionEdit;