import * as React from 'react';
import {
    Create,
    FormTab,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
} from 'react-admin';

import Aside from './Aside';

const ConfigCreate = ({...props }) => (
    <Create {...props} aside={<Aside />} >
        <SimpleForm redirect="list">
            <FormTab label="Configuration" path="">
    			<TextInput source="key" validate={required() }/>
    			<TextInput source="module" validate={required()} />
        		<TextInput
        	        source= 'value_json'
        	        format={ value_json => JSON.stringify(value_json) }
        	        parse= { value_json => JSON.parse(value_json) }
        	        validate={required()}
                />
        		<BooleanInput source="disabled" />
            </FormTab>
        </SimpleForm>
    </Create>
);

export default ConfigCreate;