import BusinessIcon from '@material-ui/icons/Business';
import ConventionCreate from './ConventionCreate';
import ConventionEdit from './ConventionEdit';
import ConventionList from './ConventionList';

export default {
    list: ConventionList,
    create: ConventionCreate,
    edit: ConventionEdit,
    icon: BusinessIcon,
};
