import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MembershipCreate from './MembershipCreate';
import MembershipEdit from './MembershipEdit';
import MembershipList from './MembershipList';

export default {
    list: MembershipList,
    create: MembershipCreate,
    edit: MembershipEdit,
    icon: AssignmentIndIcon,
};
