import * as React from 'react';
import {
    Datagrid,
    Filter,
    List,
    SearchInput,
    TextField,
    BooleanField,
    NumberField,
} from 'react-admin';

import Aside from './Aside';
import TrackEditEmbedded from './TrackEditEmbedded';

const TrackFilter = ({...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const TrackList = ({...props }) => (
    <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        filters={<TrackFilter/>}
        aside={<Aside />}
        bulkActionButtons={false}
    >
        <Datagrid
            rowClick='edit'
            expand={<TrackEditEmbedded />}
            optimized
        >
        	<NumberField source="convention_id" />
        	<NumberField source="track_id" />
        	<TextField source="name" />
        	<TextField source="membership" />
        	<BooleanField source="disabled" />
        	<TextField source="upgrade_message" />
        </Datagrid>
    </List>
);

export default TrackList;
