import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { render } from 'react-dom';
import dataProvider from './dataProvider';
import convention from './convention';
import membership from './membership';
import attendee from './attendee';
import email from './email';
import track from './track';
import event from './event';
import config from './config';
import authProvider from './authProvider';
import Dashboard from './Dashboard';
import MyLogin from './layout/MyLogin';
import MyLayout from './layout/MyLayout';

render(
    	<Admin title="Recess Admin"
    	dashboard={Dashboard}
    	layout = {MyLayout}
		authProvider={authProvider}
		dataProvider={dataProvider}
		loginPage={MyLogin}
    	>
		<Resource name="convention" {...convention} / >
		<Resource name="membership" {...membership} / >
		<Resource name="attendee" {...attendee} / >
		<Resource name="track" {...track} / >
		<Resource name="event" {...event} / >
		<Resource name="email" {...email} / >
		<Resource name="config" {...config} / >
        </Admin>,
	document.getElementById('root')
);