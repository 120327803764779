import * as React from 'react';
import {
    Datagrid,
    Filter,
    List,
    SearchInput,
    TextField,
    TextInput,
    BooleanField,
    NumberField,
} from 'react-admin';

import Aside from './Aside';

const ConventionFilter = ({...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="username" />
        <TextInput source="email" />
    </Filter>
);

const ConventionList = ({...props }) => (
    <List
        {...props}
        filters={<ConventionFilter/>}
        sort={{ field: 'convention_id', order: 'DESC' }}
        aside={<Aside />}
        bulkActionButtons={false}
        exporter={false}
    >
    <Datagrid
        rowClick='edit'
        optimized
    >
    	<NumberField label = 'Convention ID' source="convention_id" />
    	<TextField source="name" />
    	<TextField source="description" />
    	<TextField source="earliest_email_date" />
    	<TextField source="force_email_dates" />
    	<BooleanField source="enabled" />
    </Datagrid>
    </List>
);

export default ConventionList;
