import EmailIcon from '@material-ui/icons/Email';
import EmailCreate from './EmailCreate';
import EmailEdit from './EmailEdit';
import EmailList from './EmailList';

export default {
    list: EmailList,
    create: EmailCreate,
    edit: EmailEdit,
    icon: EmailIcon,
};
