import * as React from 'react';
import PropTypes from 'prop-types';
import {    Edit,
            SimpleForm,
            TextInput,
            required
} from 'react-admin';

const MembershipEditEmbedded = ({...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm>
            <TextInput
                source="name"
                defaultValue=""
                validate={required()}
            />
        </SimpleForm>
    </Edit>
);

MembershipEditEmbedded.propTypes = {
    record: PropTypes.object,
    basePath: PropTypes.string,
    resource: PropTypes.string,
    id: PropTypes.string,
};

export default MembershipEditEmbedded;
