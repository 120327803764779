import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    main: {
        [theme.breakpoints.up('sm')]: {
            width: 200,
            margin: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            overflowX: 'hidden',
            margin: 0,
        },
    },
}));

const Aside = () => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Typography variant="h6">Events</Typography>
            <Typography variant="body2">
                Events are distinct individual events that are configured against a track.
            </Typography>
        </div>
    );
};

export default Aside;
